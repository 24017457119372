export const GET_PAGES_CATEGORIES = "GET_PAGES_CATEGORIES"
export const GET_SETTINGS = "GET_SETTINGS"
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_BLOGS = "GET_BLOGS"
export const GET_ONE_BLOG = "GET_ONE_BLOG"
export const GET_ONE_INSURANCE = "GET_ONE_INSURANCE"
export const GET_ABOUT_US = "GET_ABOUT_US"
export const SAVE_OFFER_DATAS = "SAVE_OFFER_DATAS"
export const RESET_DATA = "RESET_DATA"
export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES"
export const GET_CONCLUSION_DATA = "GET_CONCLUSION_DATA"
export const GET_LANDING_DATA = "GET_LANDING_DATA"
export const OPEN_MODAL = "OPEN_MODAL"
export const OPEN_MODAL_SENT_MESSAGE = "OPEN_MODAL_MESSAGE"
export const CLOSE_MODAL = "CLOSE_MODAL"
export const LAST_DELAY_TIME = "LAST_DELAY_TIME"
