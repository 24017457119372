import { combineReducers } from "redux"
import persistReducer from "redux-persist/es/persistReducer"
import { strapiReducer } from "./strapiReducers"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "strapiReducer",
  storage,
  blacklist: ["offerDatas", "lastDelayTime"],
}

const rootReducer = combineReducers({
  strapiReducer: persistReducer(persistConfig, strapiReducer),
})

export default rootReducer
