import {
  CLOSE_MODAL,
  CLOSE_WHITE_BACKGROUND,
  GET_ABOUT_US,
  GET_BLOGS,
  GET_CONCLUSION_DATA,
  GET_LANDING_DATA,
  GET_ONE_BLOG,
  GET_ONE_INSURANCE,
  GET_PAGES_CATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCT_CATEGORIES,
  GET_SETTINGS,
  LAST_DELAY_TIME,
  OPEN_MODAL,
  OPEN_MODAL_SENT_MESSAGE,
  OPEN_WHITE_BACKGROUND,
  RESET_DATA,
  SAVE_OFFER_DATAS,
} from "../actions/actionTypes"

const initialState = {
  productsList: [],
  categoriesList: [],
  ostaloList: [],
  settings: {},
  blogsList: [],
  oneBlog: [],
  oneInsurance: [],
  aboutUs: {},
  offerDatas: {
    names: "",
    email: "",
    call_mobile: "",
  },
  primaryProductCategory: [],
  secondaryProductCategory: [],
  productCategory: [],
  conclusion: {},
  landingData: {},
  isOpenModal: false,
  isOpenModalSentMessage: false,
  lastDelayTime: null,
}

const strapiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGES_CATEGORIES:
      return {
        ...state,
        categoriesList: action.payload.slice(0, action.payload.length - 1),
        ostaloList: action.payload.slice(-1),
      }

    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }

    case GET_PRODUCTS:
      return {
        ...state,
        productsList: action.payload,
      }

    case GET_BLOGS:
      return {
        ...state,
        blogsList: [...action.payload.reverse()],
      }

    case GET_ONE_BLOG:
      return {
        ...state,
        oneBlog: state.blogsList.find(blog => blog.Slug === action.payload),
      }

    case GET_ONE_INSURANCE:
      return {
        ...state,
        oneInsurance: action.payload,
      }

    case GET_ABOUT_US:
      return {
        ...state,
        aboutUs: action.payload,
      }

    case SAVE_OFFER_DATAS:
      return {
        ...state,
        offerDatas: action.payload,
      }

    case RESET_DATA:
      return {
        ...state,
        offerDatas: {
          names: "",
          email: "",
          call_mobile: "",
        },
      }

    case GET_PRODUCT_CATEGORIES:
      // console.log(action.payload)
      return {
        ...state,
        primaryProductCategory: [
          ...action.payload.filter(primary => primary.Tip === "Primarna"),
        ],
        secondaryProductCategory: [
          ...action.payload.filter(secondary => secondary.Tip === "Sekundarna"),
        ],
        productCategory: [...action.payload],
      }

    case GET_CONCLUSION_DATA:
      return {
        ...state,
        conclusion: action.payload,
      }

    case GET_LANDING_DATA:
      return {
        ...state,
        landingData: action.payload,
      }

    case OPEN_MODAL:
      return {
        ...state,
        isOpenModal: true,
      }

    case OPEN_MODAL_SENT_MESSAGE:
      return {
        ...state,
        isOpenModalSentMessage: action.data || true,
      }

    case CLOSE_MODAL:
      return {
        ...state,
        isOpenModal: false,
        isOpenModalSentMessage: false,
      }

    case LAST_DELAY_TIME:
      return {
        ...state,
        lastDelayTime: action.payload,
      }

    default:
      return state
  }
}

export { strapiReducer }
