import React from "react"
import { Provider } from "react-redux"
import configureStore from "./redux/configureStore"
import { PersistGate } from "redux-persist/integration/react"

const store = configureStore()

export default ({ element }) => (
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      {element}
    </PersistGate>
  </Provider>
)
